document.addEventListener('DOMContentLoaded', function() {
    // Find all iframes with loading="lazy" attribute
    const lazyIframes = document.querySelectorAll('iframe[loading="lazy"]');

    if (lazyIframes.length === 0) return;

    const options = {
        root: null,
        rootMargin: '200px', // Load when iframe is within 200px of viewport
        threshold: 0.1
    };

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const iframe = entry.target;
                const src = iframe.getAttribute('data-src') || iframe.getAttribute('src');

                // If we're using data-src (recommended approach)
                if (iframe.hasAttribute('data-src')) {
                    iframe.setAttribute('src', src);
                    iframe.removeAttribute('data-src');
                }

                // Once loaded, stop observing
                observer.unobserve(iframe);
            }
        });
    }, options);

    // For each lazy iframe, replace src with data-src and observe
    lazyIframes.forEach(iframe => {
        // If not already using data-src pattern, implement it
        if (!iframe.hasAttribute('data-src')) {
            const src = iframe.getAttribute('src');
            iframe.setAttribute('data-src', src);
            iframe.setAttribute('src', 'about:blank'); // Blank initial src
        }

        observer.observe(iframe);
    });
});
